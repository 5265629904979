<template>
  <div class="DialogBody">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.DialogBody {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
  flex: 1;
  overflow-y: auto;
}
</style>
